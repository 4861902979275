import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LimitedColumns } from 'state-domains/domain/subscription';

import { buildDeleteTableViewTableUrl } from '../../../network/urls';
import { sendRequestWithXSRFToken } from '../../../utils';

export const deleteTableViewTable = (
    tableViewId: string,
    tableId: string,
): Observable<LimitedColumns> => {
    const url = buildDeleteTableViewTableUrl(tableViewId, tableId);
    return sendRequestWithXSRFToken(url, '', 'DELETE').pipe(switchMap(() => observableOf({})));
};
